import { useQuery, useMutation, useQueryClient } from "react-query";
import client, { patchclient, deleteclient } from "./api-client";

const parcelQueryConfig = {
  staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
}


async function fetchCompany(user) {
  const data = await client(`company_data/${user.id}/`);
  return data;
}

export function useCompany(user) {
  return useQuery(['user', user], () => fetchCompany(user), {
    refetchAllOnWindowFocus: false,
    ...parcelQueryConfig
  });
}

export function useCompanyStatic(user) {
  return useQuery(['user', user], () => fetchCompany(user), {
    refetchAllOnWindowFocus: false,
    enabled: false,
    ...parcelQueryConfig
  });
}

async function addCompany([companyData, user]) {
  const data = await patchclient(`company_data/${user.id}/`, { body: companyData });
  return data;
}

export function useAddCompanyMutation(companyData) {
  const queryClient = useQueryClient();

  return useMutation((companyData) => addCompany(companyData), {
      onSuccess: (data, variables) => {
          queryClient.invalidateQueries(['company_data', data])
      },
  });
}

async function addSurvey(surveyData) {
  const data = await client(`company_data/survey_extension/`, { body: surveyData });
  return data;
}

export function useSurvey(surveyData) {
  const queryClient = useQueryClient();

  return useMutation((surveyData) => addSurvey(surveyData), {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['user_data'])
      },
  });
}
