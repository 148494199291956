import * as React from 'react';
import { useQuery } from 'react-query';
import { find } from 'lodash';
import { useCompanyStatic } from '../adapters/company';
import { useAuth } from './AuthContext';

const CompanyContext = React.createContext()

function CompanyProvider({ children }) {

  const { loggedUser } = useAuth();
  const { data: company, refetch } = useCompanyStatic(loggedUser)

  React.useEffect(() => {
    if (loggedUser) {
      refetch();
    }
  }, [loggedUser])

  const value = { company }

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
}

function useCompanyContext() {
  const context = React.useContext(CompanyContext)
  if (context === undefined) {
    throw new Error(`useCompany must be used within a CompanyProvider`)
  }
  return context
}

export { CompanyProvider, useCompanyContext }