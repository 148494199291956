import React from 'react'
import { useTranslation } from 'react-i18next'
import AutocompleteSingleValue from '../../../AutocompleteSingleValue'

import { useCropsfamilies } from '../../../../adapters/cropsfamilies'

export default function AutocompleteFamily({
    initValue,
    handleAutocompleteChange,
    path,
    editDisabled,
    editError,
    editErrorText,
}) {
    const { t } = useTranslation()
    const [options, setOptions] = React.useState([initValue])
    const [optValue, setOptValue] = React.useState(initValue)

    const { isLoading, data, error, isFetching, refetch } = useCropsfamilies()

    const handleOptValueChange = React.useCallback((event, newValue) => {
        setOptValue(newValue)
        try {
            const result = {
                _value: newValue,
                _path: path,
            }
            handleAutocompleteChange(result)
        } catch (error) {
            console.error(error)
        }
    }, [])

    return (
        <AutocompleteSingleValue
            initValue={initValue}
            editDisabled={editDisabled}
            label={t('Crop.cultivation')}
            isLoading={isLoading}
            data={data}
            error={error}
            isFetching={isFetching}
            refetch={refetch}
            handleOptValueChange={handleOptValueChange}
            options={options}
            setOptions={setOptions}
            getOptionLabel={(option) => `${t(option.name)}`}
            optValue={optValue}
            editError={editError}
            editErrorText={editErrorText}
        />
    )
}
