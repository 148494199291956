import client from './api-client'

const localStorageKey = '__graniot_token__'

function handleUserResponse({ access, refresh }) {
	window.localStorage.setItem(localStorageKey, access)
	return { access, refresh }
}

function handleToken(access) {
	window.localStorage.setItem(localStorageKey, access)
	return access
}

function getUser() {
	const token = getToken()
	if (!token) {
		return Promise.resolve(null)
	}
	return client('current_user/').catch((error) => {
		console.log(error)
		logout()
		return Promise.reject(error)
	})
}

function login({ username, password, rememberMe, language }) {
	return client(process.env.REACT_APP_LOGIN_URL, {
		body: { username, password, rememberMe },
		headers: { 'Accept-Language': language },
	}).then(handleUserResponse)
}

function register({ username, password }) {
	return client('register/', { body: { username, password } }).then(
		handleUserResponse
	)
}

function logout() {
	window.localStorage.removeItem(localStorageKey)
	return Promise.resolve()
}

function getToken() {
	return window.localStorage.getItem(localStorageKey)
}

export { login, register, logout, getToken, getUser, handleToken }
