import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { find } from 'lodash';


export default function AutocompleteSingleValue({ initValue, editDisabled, label, isLoading, data,
    refetch, handleOptValueChange, options, setOptions, filtered,
    getOptionSelected, getOptionLabel, optValue, editError, editErrorText, freeSolo }) {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (data) setOptions(filtered ? data.filter((d) => !find(filtered, { id: d.id })) : data);
    }, [data, filtered]);

    React.useEffect(() => {
        if (!open && !data) {
            setOptions([]);
        } else {
            refetch();
        }
    }, [open]);

    return (
        <Autocomplete
            value={optValue}
            onChange={handleOptValueChange}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}

            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}

            options={options}
            loading={isLoading}
            disabled={editDisabled}

            freeSolo={freeSolo}
            fullWidth
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    size="small"
                    fullWidth
                    error={editError}
                    helperText={editErrorText}
                    InputProps={{
                        ...params.InputProps
                    }}
                />
            )}
        />
    );
}