import { useQuery } from 'react-query'
import client from './api-client';

const parcelQueryConfig = {
  staleTime: 1000 * 60 * 60,
  cacheTime: 1000 * 60 * 60,
}

async function fetchCropsfamilies() {
  const data = await client(`cropsfamilies`)
  return data
}

export function useCropsfamilies() {
  return useQuery(['cropsfamilies'], () => fetchCropsfamilies(), {
    refetchAllOnWindowFocus: false,
    ...parcelQueryConfig
  });
}