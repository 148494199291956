import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    sadcat: {
        height: 200
    }
  }));


  export default function AppErrorPage(props) {
    const classes = useStyles();
  
    return (
    <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} className={classes.paper}>
                <img src="./logo_graniot_positivo.svg" alt="Graniot logo" height="100"/>
            </Grid>
            <Grid item xs={12} className={classes.paper}>
                <Typography variant="h3" gutterBottom>Sorry, something went wrong!</Typography>
            </Grid>
            <Grid item xs={12} className={classes.paper}>
                <Button  href="/" color="primary"  size="large"  variant="contained">Back to Home</Button>
            </Grid>
        </Grid>
    </div>)
}
