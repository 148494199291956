import { omit } from 'lodash'
const localStorageKey = '__graniot_token__'

function client(endpoint, { body, ...customConfig } = {}) {
	const token = window.localStorage.getItem(localStorageKey)
	const headers = { 'content-type': 'application/json' }
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	const config = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	}
	if (body) {
		config.body = JSON.stringify(body)
	}

	return window
		.fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${endpoint}`, config)
		.then((r) => {
			if (r.ok) return r.json()

			if (r.status === 401 && token) {
				window.localStorage.removeItem(localStorageKey)
				window.location.search += ''
			}

			return r.json().then(function (err) {
				throw err
			})
		})
}

export function deleteclient(endpoint, { body, ...customConfig } = {}) {
	const token = window.localStorage.getItem(localStorageKey)
	const headers = { 'content-type': 'application/json' }
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	const config = {
		method: 'DELETE',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	}

	return window
		.fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${endpoint}`, config)
		.then((r) => {
			console.log(r)
		})
}

export function patchclient(endpoint, { body, ...customConfig } = {}) {
	const token = window.localStorage.getItem(localStorageKey)
	const headers = { 'content-type': 'application/json' }
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	const config = {
		method: 'PATCH',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	}
	if (body) {
		config.body = JSON.stringify(body)
	}

	return window
		.fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${endpoint}`, config)
		.then((r) => {
			if (r.ok) return r.json()

			return r.json().then(function (err) {
				throw err
			})
		})
}

export function multipartclient(
	endpoint,
	{ body, ...customConfig } = {},
	method = null
) {
	const token = window.localStorage.getItem(localStorageKey)
	const headers = {}
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	const config = {
		method: method ? method : body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	}
	if (body) {
		config.body = new FormData()
		body.attachments.forEach((element, index) => {
			config.body.append(`attachments[${index}].attachment`, element)
		})
		for (const [key, value] of Object.entries(
			omit(body, ['attachments'])
		)) {
			const vl =
				key !== 'markers'
					? JSON.stringify(value).replaceAll('"', '')
					: JSON.stringify(value)
			config.body.append(key, vl)
		}
	}

	return window
		.fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${endpoint}`, config)
		.then((r) => {
			if (r.ok) return r.json()

			return r.json().then(function (err) {
				throw err
			})
		})
}

export const handleDownloadShapefile = (data) => {
	const token = window.localStorage.getItem(localStorageKey)
	fetch(
		`${process.env.REACT_APP_BACKEND_API_URL}/catalog/zoning_shapefile/`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(data),
		}
	)
		.then((response) => response.blob())
		.then((response) => {
			var a = document.createElement('a')
			document.body.appendChild(a)
			a.style = 'display: none'

			var url = window.URL.createObjectURL(response)
			a.href = url
			a.download = 'zones.zip'
			a.click()
			window.URL.revokeObjectURL(url)
		})
}

export function putclient(endpoint, { body, ...customConfig } = {}) {
	const token = window.localStorage.getItem(localStorageKey)
	const headers = { 'content-type': 'application/json' }
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	const config = {
		method: 'PUT',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	}
	if (body) {
		config.body = JSON.stringify(body)
	}

	return window
		.fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${endpoint}`, config)
		.then((r) => {
			console.log(r)
		})
}

export function clientPatch(endpoint, { body, ...customConfig } = {}) {
	const token = window.localStorage.getItem(localStorageKey)
	const headers = { 'content-type': 'application/json' }
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	const config = {
		method: 'PATCH',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
		body: JSON.stringify(body),
	}

	return window
		.fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${endpoint}`, config)
		.then((r) => {
			if (r.ok) return r.json()

			if (r.status === 401 && token) {
				window.localStorage.removeItem(localStorageKey)
				window.location.search += ''
			}

			return r.json().then(function (err) {
				throw err
			})
		})
}

export default client
