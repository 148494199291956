import * as React from 'react';

const LocationContext = React.createContext()

function LocationProvider({ children }) {
  const [location, setLocation] = React.useState(null);

  return <LocationContext.Provider value={{location, setLocation}}>{children}</LocationContext.Provider>
}

function useLocation() {
  const context = React.useContext(LocationContext)
  if (context === undefined) {
    throw new Error(`useLocation must be used within a LocationProvider`)
  }
  return context
}

export { LocationProvider, useLocation }